import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">
  Given the recent price movement, we are evaluating to decrease the amount.
    </Info>
    <p>{`In order gain access to Honey Keeper specific discord channels, you will need to link your xDai wallet that holds at least `}<inlineCode parentName="p">{`1`}</inlineCode>{` Honey.`}</p>
    <p>{`To link your account and gain access, you need to send the message `}<inlineCode parentName="p">{`!join`}</inlineCode>{` in the `}<inlineCode parentName="p">{`#🐛onboarding`}</inlineCode>{` channel.`}</p>
    <p>{`If you have trouble or do not receive a direct message from the CollabLand bot, please join the CollabLand `}<a parentName="p" {...{
        "href": "https://t.me/CollabLand"
      }}>{`support channel`}</a>{` to troubleshoot.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      